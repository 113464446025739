<template>

  <main id="main">

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="breadcrumb-hero">
        <div class="container">
          <div class="breadcrumb-hero">
            <h2>Dust</h2>
          </div>
        </div>
      </div>
      <div class="container">
        <ol>
          <li><router-link :to="{ name: 'home' }">Home</router-link></li>
          <li>Products</li>
          <li>Dust</li>
        </ol>
      </div>
    </section><!-- End Breadcrumbs -->

    <!-- ======= Product Section ======= -->
    <section id="products" class="product">
      <div class="container">

        <div class="row">
          <product>
            <template v-slot:img>
              <a href="/img/dust/Crusher_Dust.jpg" target="_blank">
                <img src="@/assets/img/dust/th_Crusher_Dust.jpg" class="img-fluid" alt="">
              </a>
            </template>
            <template v-slot:name>
              -4mm Crusher Dust
            </template>
            <template v-slot:description>
              Crusher Dust is the fines that are left over at the end of the aggregate screening process and consists of small stones
              less than 4mm down to powder or rock flower. When moistened and compacted a fine finish is able to be achieved though is
              typically less durable than roadbase products.<br/>
              <br/>
              Also available Cement Stabilised for applications such as animal shed floors.
              Notice is required as all cement stabilised products are produced at time of despatch.<br/>
              <br/>
              <b>Standards</b><br/>
              None<br/>
              <br/>
              <b>Applications</b><br/>
              Concrete Pads, Tank Pads, Paths, Olive Trees, Leveling material.
            </template>
          </product>

          <product>
            <template v-slot:img>
              <a href="/img/dust/Man_Sand.jpg" target="_blank">
                <img src="@/assets/img/dust/th_Man_Sand.jpg" class="img-fluid" alt="">
              </a>
            </template>
            <template v-slot:name>
              -4mm Manufactured Sand
            </template>
            <template v-slot:description>
              Manufactured Sand is slightly coarser than our regular dust and is ideal as a substitute for sand in concrete applications.<br/>
              <br/>
              <b>Standards</b><br/>
              None<br/>
              <br/>
              <b>Applications</b><br/>
              Concrete
            </template>
          </product>

        </div>

      </div>
    </section><!-- End Product Section -->
  </main>
</template>
<script>
import Product from '@/components/Product.vue';

export default {
  components: {
    Product,
  },
};
</script>
